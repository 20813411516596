<template>
    <div class="data-list list-maintenance list-tab-show-page">
        <div class="row-space-tbf list-contract-data">
			<div class="space-left"></div>
			<div class="content contract-data">
				<div class="items">
					<div class="item">
						<div class="container">
							<div class="icon">
								<img src="/build/images/gross_salary.png" alt="">
							</div>
							<div class="details">
								<div class="label">
									{{ $t('salary.contract-data.gross_salary') }}
								</div>
								<div v-if="data.contract_salaries" class="value">
									{{data.contract_salaries.base_salary ? data.contract_salaries.base_salary.toLocaleString() : '-'}}
								</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="container">
							<div class="icon">
								<img src="/build/images/net_salary.png" alt="">
							</div>
							<div class="details">
								<div v-if="data.dashboard_net_salary" class="label">
									{{ $t('salary.contract-data.net_salary') }}
								</div>
								<div v-if="data.dashboard_net_salary" class="value">
									{{data.dashboard_net_salary.salary.toLocaleString() + ' - ' + data.dashboard_net_salary.month}}
								</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="container">
							<div class="icon">
								<img src="/build/images/time_worked.png" alt="">
							</div>
							<div class="details">
								<div class="label">
									{{ $t('salary.contract-data.seniority_in_company') }}
								</div>
								<div class="value">
									{{data.time_worked_in_company}}
								</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="container">
							<div class="icon">
								<img src="/build/images/increase.png" alt="">
							</div>
							<div class="details">
								<div class="label">
									{{ $t('salary.contract-data.latest_increase_salary') }}
								</div>
								<div class="value">
									{{data.last_salary_increase}}
								</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="container">
							<div class="icon">
								<img src="/build/images/vacation.png" alt="">
							</div>
							<div class="details">
								<div v-if="data.dashboard_co_text" class="label">
									{{ $t('salary.contract-data.co_situation') + ' - ' + data.dashboard_co_text.year }}
								</div>
								<div v-if="data.dashboard_co_text" class="value">
									<span>{{data.dashboard_co_text.text[0]}}</span>
									<span>{{data.dashboard_co_text.text[1]}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="summary">
					<div class="title">
						{{ $t('salary.contract-data.summary_data') }}
					</div>
					<div class="container">
						<div class="box">
							<div class="label">
								<span>{{ $t('salary.contract-data.perioad_type') }}:</span>
								<span v-if="data.contract_periods && data.contract_periods.period_type && data.contract_periods.period_type.name == 'Determinata'">{{ $t('salary.contract-data.expiration_date') }}:</span>
								<span>{{ $t('salary.contract-data.icome_type') }}:</span>
								<span>{{ $t('salary.contract-data.working_conditions') }}:</span>
							</div>
							<div class="value">
								<span v-if="data.contract_periods">{{data.contract_periods.period_type ? data.contract_periods.period_type.name : '-'}}</span>
								<span v-if="data.contract_periods && data.contract_periods.period_type && data.contract_periods.period_type.name == 'Determinata'">{{data.contract_periods.expiration_date}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.income_type ? data.contract_fiscalities.income_type.name : '-'}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.work_condition_type ? data.contract_fiscalities.work_condition_type.name : '-'}}</span>
							</div>
						</div>
						<div class="box">
							<div class="label">
								<span>{{ $t('salary.contract-data.calcul_type') }}:</span>
								<span>{{ $t('salary.contract-data.base_salary') }}:</span>
								<span>{{ $t('salary.contract-data.norm') }}:</span>
								<span>{{ $t('salary.contract-data.hours_per_day') }}:</span>
								<span>{{ $t('salary.contract-data.base_function') }}:</span>
								<span>{{ $t('salary.contract-data.date_deduction') }}:</span>
								<span>{{ $t('salary.contract-data.tax') }}:</span>
							</div>
							<div class="value">
								<span v-if="data.contract_salaries">{{data.contract_salaries.calculation_type ? data.contract_salaries.calculation_type.name : '-'}}</span>
								<span v-if="data.contract_salaries">{{data.contract_salaries.base_salary ? data.contract_salaries.base_salary.toLocaleString() : '-'}}</span>
								<span v-if="data.contract_work_programs">{{data.contract_work_programs.norm ? data.contract_work_programs.norm : '-'}}</span>
								<span v-if="data.contract_work_programs">{{data.contract_work_programs.hours_per_day ? data.contract_work_programs.hours_per_day : '-'}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.basic_function ? $t('general.yes') : $t('general.no')}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.deduction_start_date | moment('DD MMM YYYY')}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.tax_exemption_type ? data.contract_fiscalities.tax_exemption_type.name : $t('general.no')}}</span>
							</div>
						</div>
						<div class="box" v-bind:class="[{third: !showDiseases}, {'show-border': showDiseases}]">
							<div class="label">
								<span>{{ $t('salary.contract-data.retired') }}:</span>
								<span>{{ $t('salary.contract-data.co_days') }}:</span>
								<span>{{ $t('salary.contract-data.cor_code') }}:</span>
								<span>{{ $t('salary.contract-data.health_homes') }}:</span>
							</div>
							<div class="value">
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.retired ? $t('general.yes') : $t('general.no')}}</span>
								<span v-if="data.contract_work_programs">{{data.contract_work_programs.annual_leave_days ? data.contract_work_programs.annual_leave_days : '-'}}</span>
								<span v-if="data.contract_details">{{data.contract_details.code_cor ? data.contract_details.code_cor : '-'}}</span>
								<span v-if="data.contract_details">{{data.contract_details.national_health_insurance_agency ? data.contract_details.national_health_insurance_agency.label : '-'}}</span>
							</div>
						</div>
						<div class="box last-item" v-bind:class="{'show-item': showDiseases}">
							<div class="label">
								<span>{{ $t('salary.fiscality.degree_of_disability') }}:</span>
								<span>{{ $t('salary.show-contract.active_from') }}:</span>
								<span>{{ $t('salary.show-contract.active_to') }}:</span>
								<span>{{ $t('salary.fiscality.invalidity_type') }}:</span>
								<span>{{ $t('salary.show-contract.active_from') }}:</span>
								<span>{{ $t('salary.show-contract.active_to') }}:</span>
							</div>
							<div class="value">
								<span v-if="data.contract_fiscalities && data.contract_fiscalities.disability_degree_type">{{data.contract_fiscalities.disability_degree_type.name}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.disability_start_date | moment('DD MMM YYYY')}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.disability_end_date | moment('DD MMM YYYY')}}</span>
								<span v-if="data.contract_fiscalities && data.contract_fiscalities.invalidity_degree_type">{{data.contract_fiscalities.invalidity_degree_type.name}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.invalidity_start_date | moment('DD MMM YYYY')}}</span>
								<span v-if="data.contract_fiscalities">{{data.contract_fiscalities.invalidity_end_date | moment('DD MMM YYYY')}}</span>
							</div>
						</div>
					</div>
					<button v-if="(data.contract_fiscalities && (data.contract_fiscalities.disability_degree_type || data.contract_fiscalities.invalidity_degree_type))" class="btn round" @click="showDiseases ? showDiseases = false : showDiseases = true">
						<div v-if="showDiseases == false" class="icon">
							<icon-plus />
						</div>
						<div v-else class="icon">
							<icon-minus />
						</div>
					</button>
				</div>
			</div>
			<div class="space-right"></div>
        </div>
    </div>
</template>

<script>
	import IconPlus from '../../../Icons/Plus'
	import IconMinus from '../../../Icons/Minus'

	export default {
		components: {
			IconPlus,
			IconMinus
		},
		data() {
			return {
				loaded: false,
				showDiseases: false
			}
		},
        props:{
            data: Object
        },
		async mounted(){
			setTimeout(() => { 
				this.loaded = true 
			}, 0)
		},

		methods: {
		}
	}
</script>

<style lang="scss">
.list-contract-data {
    .contract-data {
        display: flex;
        flex-wrap: wrap;
		@media (max-width: 1500px) {
			flex-direction: column;
		}
        .items {
			display: flex;
			flex-direction: column;
			flex: 48%;
    		margin-right: 10px;
			@media (max-width: 1500px) {
				margin-right: 0;
			}
			.item {
				width: 100%;
				margin-bottom: 15px;
				border: 1px solid #dcdee1;
				border-radius: 10px;
				overflow: hidden;
				&:last-child {
					margin-bottom: 0;
				}
				.container {
					background-color: #fff;
					width: 100%;
					font-size: 14px;
					padding: 25px 30px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					.icon{
						width: 30px;
						height: 100%;
						margin-right: 20px;
						display: flex;
    					flex: 5%;
					}
					.details{
						display: flex;
						flex-direction: column;
    					flex: 90%;
						.label{
							color: #747683;
							margin-bottom: 10px;
						}
						.value{
							display: flex;
							flex-direction: column;
							font-weight: 500;
							span:nth-child(1){
								margin-bottom: 5px;
							}
						}
					}
				}
			}
		}
		.summary {
			display: flex;
			flex-direction: column;
			flex: 48%;
			margin-left: 10px;
			border: 1px solid #dcdee1;
			border-radius: 10px;
			padding: 25px 30px 0 30px;
			position: relative;
			@media (max-width: 1500px) {
				margin-top: 15px;
				margin-left: 0;
			}
			.title{
				width: 100%;
    			text-align: center;
				font-weight: 500;
			}
			.container{
				.box {
					display: flex;
					margin: 25px 0;
					border-bottom: 1px solid #dcdee1;
					&.third {
						margin: 25px 0 0 0 !important;
						border-bottom: none;
					}
					&.show-border {
						margin: 25px 0;
						border-bottom: 1px solid #dcdee1;
					}
					&.last-item{
						margin: 0 !important;
						overflow: hidden;
						max-height: 0;
						transition: max-height  0.15s ease-out;
					}
					&.show-item{
						margin: 25px 0 !important;
						max-height: 500px;
   						transition: max-height  0.25s ease-in;
					}
					.label {
						font-size: 14px;
						color: #747683;
						display: flex;
						flex: 48%;
						margin-right: 10px;
						text-align: end;
						margin-bottom: 10px;
						flex-direction: column;
						span{
							margin-bottom: 10px;
						}
						@media (max-width: 500px) {
							font-size: 12px;
						}
					}
					.value {
						font-size: 14px;
						font-weight: 500;
						display: flex;
						flex: 48%;
						margin-left: 10px;
						text-align: start;
						flex-direction: column;
						@media (max-width: 500px) {
							font-size: 12px;
						}
						span{
							margin-bottom: 10px;
						}
					}
					&:last-child{
						border-bottom: none;
						margin: 25px 0 0 0;
					}
				}
			}
			button.btn.round {
				border-radius: 50%;
				width: 40px;
				height: 40px;
				position: absolute;
				bottom: -20px;
				left: 45%;
				border: 4px solid white;
				cursor: pointer;
				.icon{
					svg{
						display: block;
    					margin: auto;
					}
				}
				&:hover{
					background-color: #e5e4e4;
				}
			}
		}
    }
}
</style>